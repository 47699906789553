import React, { useState, useEffect } from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'

const StripeWrapper = ({ children }) => {
  const [stripe, setStripe] = useState(null)

  useEffect(() => {
    // for SSR
    if (typeof window == 'undefined') return

    // for browser
    if (window.Stripe) {
      setStripe(window.Stripe(process.env.STRIPE_API_KEY))
    } else {
      const stripeScript = document.querySelector('#stripe-js')
      stripeScript.onload = () => {
        setStripe(window.Stripe(process.env.STRIPE_API_KEY))
      }
    }
  }, []) // <-- passing in an empty array since I only want to run this hook once

  return (
    <StripeProvider stripe={stripe}>
      <Elements>{children}</Elements>
    </StripeProvider>
  )
}

export default StripeWrapper
